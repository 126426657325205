import { useRef, useState } from 'react';
import { useUser } from '../providers/UserProvider';

function Login() {
  const { signInWithPassword } = useUser();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<string | null>(null);

  return (
    <form
      className="login-form"
      onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setError(null);

        const email = emailRef.current?.value || '';
        const password = passwordRef.current?.value || '';

        signInWithPassword(email, password).catch((error) => {
          setError(error.message);
        });
      }}
    >
      <h1>Sign in</h1>
      <input ref={emailRef} type="email" placeholder="Email" />
      <div className="spacer" />
      <input ref={passwordRef} type="password" placeholder="Password" />
      <div className="spacer" />
      <button className="login-button" type="submit">
        Login
      </button>
      {error && (
        <>
          <div className="spacer" />
          <p className="error">{error}</p>
        </>
      )}
    </form>
  );
}

export default Login;
