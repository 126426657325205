import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  User,
  UserCredential,
} from 'firebase/auth';
import { useFirebase } from './FirebaseProvider';

type UserContextType = {
  user: User | null | undefined;
  signInWithPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential | void | undefined>;
  createUserWithPassword: (
    email: string,
    password: string
  ) => Promise<UserCredential | void | undefined>;
  resetPassword: (email: string) => Promise<UserCredential | void | undefined>;
  signOut: () => Promise<void>;
};

const UserContext = createContext<UserContextType>({
  user: undefined,
  signInWithPassword: async () => {},
  createUserWithPassword: async () => {},
  resetPassword: async () => {},
  signOut: async () => {},
});

export default function UserProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  let { auth } = useFirebase();
  let [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    if (auth) {
      auth.onAuthStateChanged(setUser);
    }
  }, [auth]);

  return (
    <UserContext.Provider
      value={{
        user,
        signInWithPassword: async (email: string, password: string) => {
          if (auth) {
            return signInWithEmailAndPassword(auth, email, password);
          }
        },
        createUserWithPassword: async (email: string, password: string) => {
          if (auth) {
            return createUserWithEmailAndPassword(auth, email, password);
          }
        },
        resetPassword: async (email: string) => {
          if (auth) {
            return sendPasswordResetEmail(auth, email);
          }
        },
        signOut: async () => {
          if (auth) {
            return auth.signOut();
          }
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => {
  return useContext(UserContext);
};
