import './App.scss';
import Home from './components/Home';
import Login from './components/Login';
import { useUser } from './providers/UserProvider';

function App() {
  const { user } = useUser();

  return (
    <div className="app">
      {user === undefined ? (
        <p>Loading...</p>
      ) : user === null ? (
        <Login />
      ) : (
        <Home />
      )}
    </div>
  );
}

export default App;
