import './Home.scss';
import { useEffect, useState } from 'react';
import { useFirebase } from '../providers/FirebaseProvider';
import { httpsCallable } from 'firebase/functions';

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const DROPLET_ID = 379569664,
  SMALL = 's-1vcpu-1gb',
  LARGE = 's-2vcpu-4gb-intel';
function Home() {
  const { functions } = useFirebase();

  const [dropletSize, setDropletSize] = useState<string>();
  const [dropletOnline, setDropletOnline] = useState<boolean>();
  const [minecraftServerOnline, setMinecraftServerOnline] = useState<boolean>();

  async function checkDropletSize() {
    if (!functions) return;

    const result = (
      await httpsCallable(
        functions,
        'getDropletSize'
      )({
        id: DROPLET_ID,
      })
    ).data as string;

    setDropletSize(result);

    return result;
  }

  async function checkDropletOnline() {
    if (!functions) return;

    const result = (
      await httpsCallable(
        functions,
        'getDropletStatus'
      )({
        id: DROPLET_ID,
      })
    ).data as boolean;

    setDropletOnline(result);

    return result;
  }

  async function checkServerOnline() {
    if (!functions) return;

    const getMinecraftServerStatus = async ({
      address,
    }: {
      address: string;
    }) => {
      const response = await fetch(
        `https://api.mcstatus.io/v2/status/java/${address}`
      );
      const data = await response.json();
      return data;
    };

    const result = await getMinecraftServerStatus({
        address: 'minecraft.screendead.com',
      }),
      online = result.online as boolean;

    setMinecraftServerOnline(online);

    return online;
  }

  useEffect(() => {
    checkDropletSize();
    checkDropletOnline();
    checkServerOnline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button
        onClick={async () => {
          if (!functions) return;

          if (minecraftServerOnline) {
            console.log('Waiting for droplet to turn off...');
            await httpsCallable(
              functions,
              'turnDropletOff'
            )({
              id: DROPLET_ID,
            });

            let _serverOnline = true;
            let _online = true;
            let _tries = 0;

            while (_online && _tries < 1000) {
              await delay(1000);

              console.log('Checking if droplet is turned off...');
              if (_serverOnline) {
                _serverOnline = (await checkServerOnline()) || false;
              }
              _online = (await checkDropletOnline()) || false;
              _tries++;
              console.log('Droplet turned off:', !_online);
            }

            if (!_online) {
              setDropletSize(undefined);

              console.log(`Resizing droplet to ${SMALL}..`);
              await httpsCallable(
                functions,
                'setDropletSize'
              )({
                id: DROPLET_ID,
                size: SMALL,
              });

              console.log('Checking droplet size...');
              const _size = await checkDropletSize();
              console.log(`Droplet size: ${_size}`);

              if (_size === SMALL) {
                console.log('Powerdown successful!');
              } else {
                // Droplet resize failed
              }
            } else {
              // Droplet poweroff failed
            }
          } else if (dropletSize === SMALL) {
            setDropletSize(undefined);

            console.log(`Resizing droplet to ${LARGE}...`);
            await httpsCallable(
              functions,
              'setDropletSize'
            )({
              id: DROPLET_ID,
              size: LARGE,
            });

            console.log('Checking droplet size...');
            const _size = await checkDropletSize();
            console.log(`Droplet size: ${_size}`);

            if (_size === LARGE) {
              console.log('Waiting for droplet to turn on...');
              await httpsCallable(
                functions,
                'turnDropletOn'
              )({
                id: DROPLET_ID,
              });

              let _online = false;
              let _tries = 0;

              while (!_online && _tries < 1000) {
                await delay(250);

                console.log('Checking if droplet is online...');
                _online = (await checkDropletOnline()) || false;
                _tries++;
                console.log('Droplet online:', _online);
              }

              if (_online) {
                _online = false;
                _tries = 0;

                while (!_online && _tries < 250) {
                  await delay(1000);

                  console.log('Checking if server is online...');
                  _online = (await checkServerOnline()) || false;
                  _tries++;
                  console.log('Server online:', _online);
                }

                if (_online) {
                  console.log('Server is online!');
                } else {
                  // TODO: Set status to failure
                  console.log('Server failed to turn on.');
                }
              } else {
                // TODO: Set status to failure
                console.log('Droplet failed to turn on.');
              }
            }
          }
        }}
      >
        Turn server {dropletSize === SMALL ? 'ON' : 'OFF'}
      </button>
      <p>
        <strong>Droplet size:</strong>{' '}
        {dropletSize === undefined ? 'Loading...' : dropletSize}
      </p>
      <p>
        <strong>Droplet:</strong>{' '}
        {dropletOnline === undefined ? (
          <span className="unknown">Loading...</span>
        ) : dropletOnline ? (
          <span className="online">Online</span>
        ) : (
          <span className="offline">Offline</span>
        )}
      </p>
      <p>
        <strong>Minecraft Server:</strong>{' '}
        {minecraftServerOnline === undefined ? (
          <span className="unknown">Loading...</span>
        ) : minecraftServerOnline ? (
          <span className="online">Online</span>
        ) : (
          <span className="offline">Offline</span>
        )}
      </p>
    </>
  );
}

export default Home;
