/**
 * Author: Jack Lusher (jack.lusher@acue.io) & Michael Stevenson (michael.stevenson@acue.io)
 * Date:   23/08/2022
 * Time:   16:30
 *
 * Description:
 * This file contains the AppProvider component. This component is responsible
 * for providing Firebase services to the entire application.
 *
 * Usage:
 * To use this component, you must import the useFirebase hook and use it in the element
 * you wish to access Firebase services from.
 *
 * Notes:
 * The useFirebase hook can only be used in an element that is a child (of any depth)
 * of an AppProvider component. These can be in the same file or in a different file.
 *
 * Example:
 * function App() {
 *   let { app, analytics, auth, firestore } = useFirebase();
 *
 *   return (
 *     ...
 *   );
 * }
 */

import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics } from 'firebase/analytics';
import { getAuth, Auth } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import {
  getFunctions,
  Functions,
  connectFunctionsEmulator,
} from 'firebase/functions';

type FirebaseContextType = {
  app: FirebaseApp | null;
  analytics: Analytics | null;
  auth: Auth | null;
  firestore: Firestore | null;
  functions: Functions | null;
};

const FirebaseContext = createContext<FirebaseContextType>({
  app: null,
  analytics: null,
  auth: null,
  firestore: null,
  functions: null,
});

export default function FirebaseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [variables, setVariables] = useState<FirebaseContextType>({
    app: null,
    analytics: null,
    auth: null,
    firestore: null,
    functions: null,
  });

  useEffect(() => {
    const initialize = async () => {
      const firebaseConfig = {
        apiKey: 'AIzaSyAN37TwhjKBCkssl-qcvDuRcPIQ_VoIwDQ',
        authDomain: 'krista-screendead-com.firebaseapp.com',
        projectId: 'krista-screendead-com',
        storageBucket: 'krista-screendead-com.appspot.com',
        messagingSenderId: '647680282268',
        appId: '1:647680282268:web:dad6ba36b5203b4650e759',
        measurementId: 'G-GVJ747MEVG',
      };

      const _app = initializeApp(firebaseConfig);
      const _analytics = getAnalytics(_app);
      const _auth = getAuth(_app);
      const _firestore = getFirestore(_app);
      const _functions = getFunctions(_app);
      if (process.env.NODE_ENV === 'development') {
        connectFunctionsEmulator(_functions, 'localhost', 5001);
      }

      setVariables({
        app: _app,
        analytics: _analytics,
        auth: _auth,
        firestore: _firestore,
        functions: _functions,
      });
    };

    initialize();
  }, []);

  return (
    <FirebaseContext.Provider value={variables}>
      {children}
    </FirebaseContext.Provider>
  );
}

export const useFirebase = () => {
  return useContext(FirebaseContext);
};
